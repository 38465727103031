<template>
  <section class="m-honor-wrapper">
    <div class="m-honor-title">公司荣誉</div>
    <div class="m-honor-list">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="item in honorList" :key="item.id">
          <div class="m-item">
            <div
              v-scroll-lazy="{ mode: 'bg', src: item.minImg }"
              class="m-honor-image"
            ></div>
            <div class="m-honor-desc">
              <div
                v-for="(award, index) in item.awards"
                :key="index"
                class="text"
              >
                {{ award }}
              </div>
            </div>
          </div>
        </swiper-slide>
        <div
          v-if="honorList.length > 1"
          slot="pagination"
          class="swiper-pagination-h"
        ></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import 'swiper/dist/css/swiper.css'
import { honorList } from '../constants/index'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default defineComponent({
  name: 'DeskHonor',
  components: {
    swiper,
    swiperSlide
  },
  setup() {
    return {
      honorList,
      swiperOption: {
        pagination: { el: '.swiper-pagination-h' },
        autoplay: false
      }
    }
  }
})
</script>

<style lang="less" scoped>
.m-honor-wrapper {
  display: block;
  background: #fff;
  overflow: hidden;
  .m-honor-title {
    font-size: 0.56rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0.6rem;
  }
  .m-honor-list {
    width: 7.5rem;
    margin: 0 auto 1.9rem;
    .m-item {
      width: 6.78rem;
      margin: 0 0.36rem;
      .m-honor-image {
        width: 100%;
        height: 3.23rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 0.1rem;
      }
      .m-honor-desc {
        font-size: 0.3rem;
        color: #333;
        margin-top: 0.2rem;
        line-height: 0.6rem;
        .text {
          white-space: nowrap;
        }
      }
    }
  }
}
.swiper-slide {
  width: 7.5rem !important;
}
.swiper-container {
  overflow: visible;
}
.swiper-pagination-h {
  bottom: -0.9rem;
}
/deep/ .swiper-pagination-bullet {
  width: 0.16rem;
  height: 0.16rem;
  background: #badcff;
  margin: 0 0.12rem;
}
/deep/ .swiper-pagination-bullet-active {
  background: #007cff;
}
</style>
